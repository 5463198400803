import marlene_mcneese_img_1 from '../../images/Deeper-dive-marlene-mcneese-profile.png';
import marlene_mcneese_img_1_mobile from '../../images/Deeper-dive-marlene-mcneese-profile-mobile.png';

const fullName = "Marlene McNeese";



const copyListMarleneMcneese = [
   
    {
        type: "image",
        content: {
            src: marlene_mcneese_img_1,
            mobileSrc: marlene_mcneese_img_1_mobile,
            alt: "A woman with glasses, Marlene, smiling at the camera",
            caption: "Marlene McNeese"
        }
    },
    {
        type: "text",
        content: "<h3 class=\"title marlene-mcneese\">A CONVERSATION WITH MARLENE McNEESE, CO-CHAIR, PRESIDENTIAL ADVISORY COUNCIL ON HIV/AIDS, AND DEPUTY ASSISTANT DIRECTOR, HIV/STD AND VIRAL HEPATITIS PREVENTION, HOUSTON HEALTH DEPARTMENT"
    },
    {
        type: "text",
        content: "June 27 is National HIV Testing Day (NHTD), a day to emphasize and encourage HIV testing. Yet with the COVID-19 pandemic continuing to impact our communities, public health departments, and healthcare facilities, this year we mark NHTD looking to understand the impact COVID-19 has had on HIV testing."
    },
    {
        type: "text",
        content:"To understand where things currently stand, we spoke with Marlene McNeese, Co-chair, Presidential Advisory Council on HIV/AIDS, and Deputy Assistant Director, HIV/STD and Viral Hepatitis Prevention, Houston Health Department. Marlene shares her expertise in HIV testing implementation and addresses how the pandemic continues to impact HIV testing, with a particular focus on communities in the South, where the HIV epidemic continues to have a disproportionate impact."
    },
    {
        type: "text",
        content:"<p><strong>How has the COVID-19 pandemic impacted HIV-testing rates?</strong></p>"
    },
    {
        type: "text",
        content:"<strong><em>Marlene McNeese:</em></strong><br>Like many communities, we saw a huge reduction in terms of access to HIV screening. When I reflect on what occurred in Houston, I think about the lessons we've learned from responding to natural disasters and other big emergency response situations, like hurricanes. Those experiences have taught us that it becomes exponentially more difficult when the people you need to rely on, like your first responders, are also impacted by the emergency at hand."
    },
    {
        type: "text",
        content:"For COVID-19, this meant there was an immediate impact on the capacity of our staff and the workforce to respond. Many people were sick; many people were being impacted by COVID directly, requiring quarantining and other measures. So we had to quickly onboard additional staff to respond to COVID-19, and that meant a large number of our HIV prevention and HIV surveillance staff were redirected away from HIV to assist with COVID-19.<sup>1</sup>"
    },

    {
        type: "text",
        content:"The good news is that I think we’re slowly returning to pre–COVID-19 access levels to HIV testing. And I say that to acknowledge that they weren’t perfect before COVID-19. In a non–Medicaid-expansion state like Texas,<sup>2</sup> with the highest uninsured rate in the country,<sup>3</sup> the safety net services provided by health departments like ours is a critical access point to preventive services like HIV testing. For agencies like ours, which adopted a COVID-19 response, we’re beginning to think through, how can we leverage the additional resources and experience that came out of the COVID-19 response in order to improve access points to HIV testing?"
    },
    
    {
        type: "blockquote",
        content: "“How can we leverage the additional resources and experience that came out of the COVID-19 response in order to improve access points to HIV testing?”",
        author: fullName
    },
   
    {
        type: "text",
        content: "<strong>National claims data indicate that HIV testing declined 17% in 2020 from the average yearly volume from 2017-2019 and then returned to just 5% below that average in 2021.<sup>4</sup> What are the data telling us about testing and diagnosis rates across the Southern United States?</strong>"
    },
    {
        type: "text",
        content: "I've lived and worked in the South for the greater portion of my adult life, and I’ve always been astounded by the resiliency of communities across the South that have historically been challenged in the context of many racial and political circumstances.<sup>5</sup> I believe the data are repeating the same story for HIV and for COVID-19 that we’ve seen across any other health issue. But I think that particularly in the South, we’ve seen that while access to testing has declined, in some cases there’s been some ability to stabilize access to HIV testing.<sup>4</sup> At the same time, we’ve seen new diagnoses decline by as much as 21% nationally,<sup>4</sup> and that’s very scary, because I don't believe it’s a result of our being able to eliminate or reduce HIV burden. I think it's really demonstrating, yet again, the disparities that continue to affect vulnerable communities, especially in the South, that don't easily have access to services like testing for HIV."
    },
    {
        type: "text",
        content: "<strong>What are some of the longer-term implications of the decline in testing and linkage to care during the COVID-19 pandemic?</strong>"
    },
    {
        type: "text",
        content: "I think the health and economic impact of the pandemic may be long-lasting, and I think we’re going to see some of those repercussions for a couple of years to come. We are likely to see greater disparities among people who are most vulnerable to acquiring HIV, before we see improvements. Among vulnerable communities, disruptions in access to services have major consequences. When you compound the realities and the fears of trying to respond to a pandemic like COVID-19 with the already pre-existing socioeconomic constraints like unemployment, housing instability, and food insecurity, these can and do greatly impact disease outcomes.<sup>6</sup>"
    },
    {
        type: "text",
        content: "At the same time, I think some agencies will continue to experience a decline in their capacity to provide HIV-testing services. Some agencies either have closed or may be facing closure altogether moving forward. So there is a need to try and respond to what we can already foresee in terms of the continuing challenges some of our more vulnerable communities are likely to face. We are not out of the woods yet."
    },
    {
        type: "text",
        content: "<strong>What are some of the innovative strategies that we can learn from the COVID-19 testing response to increase HIV testing?</strong>"
    },
    {
        type: "text",
        content: "Let me start off first by saying that I'm really proud of the way that we were able to put up COVID-19 testing sites and access points rapidly across our area. Houston is larger than several states in this country,<sup>7,8</sup> and being able to ensure that all segments of the community had access to these services was important."
    },
    {
        type: "text",
        content: "<strong>Integrating Health Screenings</strong>"
    },
    {
        type: "text",
        content: "A good example is what occurred with many of our partners in federally qualified health centers (FQHCs) in our area. Some of the work that they did was probably the most creative and innovative that was implemented during the <nbr class=\"text-nowrap\">COVID-19</nbr> pandemic. They were better able to begin thinking through and then providing the integration of COVID screening services with the myriad of other services that they offer as healthcare providers, including HIV screening. When a patient came in for COVID-19 related services, many of our FQHCs began to document whether or not they saw a recent documented HIV test in the health record. And then if they hadn't seen a recent test, it was an automatic trigger for them to offer HIV screening."
    },
    {
        type: "text",
        content: "<strong>Self-Testing With Telehealth Support</strong>"
    },
    {
        type: "text",
        content: "I do think there are legitimate concerns with home HIV testing that should be important for programs and services to think through before launching such a service. Folks receiving a potentially major life diagnosis without access to clinical or care management is not a good thing. And so I strongly believe in home HIV testing programs and services that do have a level of comprehensive follow-up in terms of support along with the actual HIV test."
    },

    {
        type: "text",
        content: "One strategy that I’ve seen offers an approach similar to COVID-19 testing that we're trying to model for our HIV at-home testing.<sup>9</sup> The program offers an online registration, which makes it easy and comfortable for many to do within their homes. They can choose to have a test kit mailed to their home, but more importantly, once they have registered, they immediately have access to a care manager who can respond to questions before they test, after they receive the result, and so on. That access to a care provider is important. "
    },
    {
        type: "text",
        content: "<strong>Applying a Status-Neutral Approach</strong>"
    },
    {
        type: "text",
        content: "It's important to take a status-neutral approach, so that regardless of the result, there are steps to take.<sup>10</sup> If you have a negative result, what's next? Who can you talk to about how you need to continue your testing support and how you may be able to get linked to other social service needs that you might have. If you find out that you're living with HIV, it's important to have the option to begin treatment as quickly as possible.<sup>10</sup> That means being able to rapidly get assessed by a clinical provider and getting a prescription. If you choose not to come in-house to a physical clinic location, how can you have access to the same rapid treatment and preventative services as anyone who comes in person? With telehealth, it may be much simpler and easier, and in many cases, faster, to have a virtual appointment conducted with a clinician."
    },
    {
        type: "text",
        content: "I don't think any singular approach to HIV testing is going to work in any community. Our people and communities are too diverse. So our methods, including mass testing, home testing, drive-through testing, all of the great things that we've learned that are effective in bringing services to people at scale and at mass, we need to duplicate those."
    },
    {
        type: "text",
        content: "<strong>Are there any potential silver linings from the uptake of COVID-19 testing that can be leveraged to increase HIV testing?</strong>"
    },
    {
        type: "text",
        content: "I would definitely agree that there were some sort of silver linings in the COVID-19 response that we saw in our communities. For one, I think that people have become more familiar with the importance of knowing their status [based on positive or negative test for a disease], and of knowing how to quickly and easily access the variety of ways in which they can receive a test. Those are certainly things that we can apply to HIV testing."
    },
    {
        type: "text",
        content: "With COVID-19, things were ever-changing…rapidly changing. Our response and information-sharing had to be the same. For HIV testing, we need to ensure that our message and awareness around knowing your HIV status and where you can access some of these supports and services should be equally as routine and rapidly updated so that communities can be aware of their options."
    },
    {
        type: "text",
        content: "<strong>In what ways does HIV screening play a role in our national efforts to end the HIV epidemic?</strong>"
    },
    {
        type: "text",
        content: "Goal One of the National HIV/AIDS Strategy speaks to preventing new infections by not only increasing knowledge of status, but also by expanding the capacity of the healthcare delivery system to diagnose HIV.<sup>10</sup> The “Diagnose” pillar in the Ending the HIV Epidemic initiative also seeks to ensure that all people living with HIV are diagnosed as early as possible.<sup>11</sup> HIV screening plays a vital role in these efforts, by ensuring that we diagnose as many people as possible using as many screening technologies and access points as possible."
    },
    {
        type: "text",
        content: "Timely diagnosis, along with early and sustained viral suppression through the use of antiretroviral therapy, can reduce HIV transmission.<sup>12</sup> That's important. Access to other prevention tools may not always be sufficient or at scale. Access to an HIV test may be the only prevention service some people will ever get. So we need to ensure that a cost-effective service such as HIV testing is scaled at mass, just like this country did for COVID-19."
    },
    {
        type: "blockquote",
        content: "“Access to an HIV test may be the only prevention service some people will ever get. So we need to ensure that a cost-effective service such as HIV testing is scaled at mass, just like this country did for COVID-19.”",
        author: fullName
    },
    {
        type: "text",
        content: "<strong>National HIV Testing Day is coming up on June 27. How can we better encourage people to get tested for HIV and know their status?</strong>"
    },
    {
        type: "text",
        content: "I do think that our messages for HIV testing should be very clear, and I don’t think we’ve been as clear as we could be as options have evolved. Messages in the past have been earmarked in many cases by blame and shame: You need to know because you don't know who may have given you HIV. You need to know, so you can stop doing the “risky” things that you are doing."
    },
    {
        type: "text",
        content: "I think the message needs to be really simple, especially given what we know about the effective strategies we have today for preventing and treating HIV. Anyone who is sexually active should be made aware of their sexual health status, and this includes HIV status. Knowing that information allows me to maintain control over my own sexual health."
    },
    {
        type: "text",
        content: "I would strongly hope and encourage that as we create future messages, we focus not on the fear and stigma and shame and blame, but on the fact that we have tools that allow people who are living with HIV to thrive, be sexually healthy, get to and stay undetectable, and prevent transmitting HIV to their partners through sex.<sup>12</sup> The message of being undetectable (<200 copies/mL) and how important viral suppression is, is not talked about enough. With that understanding, communities have an opportunity to help reduce the fear and stigma related to the possibility of learning about an HIV diagnosis."
    }

]


export default copyListMarleneMcneese;